import Slider from "react-slick";
import { Images_Src } from "../../config/images";

const Collection = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    lazyLoad: true,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToScroll: 1,
    cssEase: "cubic-bezier(0.070, 0.820, 0.750, 0.750)",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="page-home__collection">
      <div className="page-home__collection__carousel">
        <Slider {...settings}>
          {Images_Src.carousel.map((imgSrc, index) => (
            <img src={imgSrc} key={index} alt="ship" />
          ))}
        </Slider>
      </div>
      <div className="page-home__collection__text">
        {
          "Galactium is a utility-based collection of 3333 unique galactic fighter pilot NFTs on the Ethereum blockchain.  NFTs grant owners exclusive access to Galactium, a multiplayer, PVP arena where players fight to the death in deep space. The beta version of our game will be playable RIGHT AFTER LAUNCH. NO MORE WAITING on empty promises. Just jump right in and enter deep space..."
        }
      </div>
    </div>
  );
};

export default Collection;
