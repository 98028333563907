import { Images_Src } from "../../config/images";
import ScrollAnimation from "react-animate-on-scroll";

const MintingSoon = () => {
  return (
    <div className="page-home__minting-soon">
      <div className="page-home__minting-soon__header">
        <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
          <img
            className="page-home__minting-soon__header__img"
            src={Images_Src.ships.shipOne}
            alt="ship"
          />
        </ScrollAnimation>

        <img
          className="page-home__minting-soon__header__title"
          src={Images_Src.title}
          alt="GALACTIUM"
        />

        <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
          <img
            className="page-home__minting-soon__header__img"
            src={Images_Src.ships.shipTwo}
            alt="ship"
          />
        </ScrollAnimation>
      </div>

      <div className="page-home__minting-soon__text">{"minting soon..."}</div>
    </div>
  );
};

export default MintingSoon;
