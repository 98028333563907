import { useEffect } from "react";
import { useRef } from "react";
import { Images_Src } from "../../config/images";

const data = [
  {
    title: "PHASE I",
    content: [
      "Genesis Collection Mint",
      "Multiplayer Arena Mode released (Team Deathmatch)",
      "Play to Earn ETH in Community Competitions",
      "New in-game modes released",
    ],
  },
  {
    title: "PHASE II",
    content: [
      "Partnered NFT projects integrated into the Galactium metaverse",
      "$dark utility token introduced",
      "Staking introduced",
      "Wagering Eth & $dark against other players in arena battles",
      "NFT drop 2.0",
      "New in-game items/tokens dropped & registered on L2 Ethereum chain",
    ],
  },
  {
    title: "PHASE III",
    content: [
      "Mass-multiplayer role-player Galactium metaverse with integrated communities",
      "Fully-fledged Web3 Galactium economy",
      "Galactium NFT holders determine the future of the game through a DAO",
    ],
  },
];

const Roadmap = () => {
  const imgRef = useRef(null);
  const cardRef = useRef(null);
  const progressBarWrapref = useRef(null);
  const progressRef = useRef(null);
  const progressStopsRef = useRef([]);

  useEffect(() => {
    const height = cardRef?.current.scrollHeight;
    progressBarWrapref.current.style.height = height;

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const start = cardRef?.current.offsetTop;
    const height = cardRef?.current.scrollHeight;
    const end = start + height;
    if (scrollTop < start - 500 || scrollTop > end - 300) return;
    const percent = Math.min(((scrollTop + 400 - start) * 100) / height, 100);
    progressRef.current.style.height = `${percent}%`;
    imgRef.current.style.transform = `translateY(${
      (scrollTop + 400 - start) / 2
    }px)`;
  };

  return (
    <div className="page-home__roadmap">
      <div className="page-home__roadmap__title" style={{textShadow: "1px 1px 6px purple"}}>{"roadmap"}</div>
      <div className="page-home__roadmap__content">
        <div>
          <img
            className="page-home__roadmap__content__img"
            src={Images_Src.roadmap}
            alt="ship"
            ref={imgRef}
          />
        </div>
        <div
          className="page-home__roadmap__content__progress-bar-wrap"
          ref={progressBarWrapref}
        >
          <div
            className="page-home__roadmap__content__progress-bar-wrap__progress-bar"
            ref={progressRef}
          />
        </div>
        <div className="page-home__roadmap__content__card" ref={cardRef}>
          {data.map((item, index) => (
            <div
              className="page-home__roadmap__content__card__item"
              key={index}
            >
              <div
                className="page-home__roadmap__content__card__item__progressStop"
                ref={(ref) => progressStopsRef.current.push(ref)}
              />
              <div className="page-home__roadmap__content__card__item__title">
                {item.title}
              </div>
              <ul className="page-home__roadmap__content__card__item__content">
                {item.content.map((contentData, index) => (
                  <li
                    className="page-home__roadmap__content__card__item__content__list"
                    key={index}
                  >
                    {contentData}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
