import { useRef, useState } from "react";
import { Images_Src } from "../config/images";

const NavBar = ({ scrollClick }) => {
  const [hamburgerStatus, setHamburgerShow] = useState(false);
  const sidebarRef = useRef(null);
  const toggleVisible = () => {
    if (hamburgerStatus) {
      setHamburgerShow(false);
      sidebarRef.current.style.transform = "scaleX(0)";
    } else {
      setHamburgerShow(true);
      sidebarRef.current.style.transform = "scaleX(1)";
    }
  };
  return (
    <div className="navbar">
      <div className="navbar__navigation">
        <div className="navbar__navigation__nav-items">
          <div
            className="navbar__navigation__nav-items__nav-item"
            onClick={() => scrollClick("utility")}
          >
            {"Utility"}
          </div>
          <div
            className="navbar__navigation__nav-items__nav-item"
            onClick={() => scrollClick("roadmap")}
          >
            {"Roadmap"}
          </div>
          <div
            className="navbar__navigation__nav-items__nav-item"
            onClick={() => scrollClick("faq")}
          >
            {"Faq"}
          </div>
          <div
            className="navbar__navigation__nav-items__nav-item"
            onClick={() => scrollClick("team")}
          >
            {"Team"}
          </div>
          <div
            className="navbar__navigation__nav-items__nav-item"
            onClick={() => scrollClick("faction")}
          >
            {"Factions"}
          </div>
        </div>
        <div className="navbar__navigation__social-icons">
          <a href="https://discord.gg/s84BuZvg3h">
            <img src={Images_Src.icons.discord} alt="discord" />
          </a>
          <a href="Https://twitter.com/galactiumnft">
            <img src={Images_Src.icons.twitter} alt="twitter" />
          </a>
          <a href="https://instagram.com/galactiumnft">
            <img src={Images_Src.icons.instagram} alt="instagram" />
          </a>
        </div>
        <div
          className={"hamburger " + (hamburgerStatus ? "active" : "")}
          onClick={toggleVisible}
        >
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </div>
      </div>
      <div
        className="navbar__sidebar"
        ref={sidebarRef}
        onClick={() => toggleVisible()}
      >
        <div
          to="/"
          className="navbar__sidebar__nav-item"
          onClick={() => scrollClick("utility")}
        >
          {"Utility"}
        </div>
        <div
          to="/"
          className="navbar__sidebar__nav-item"
          onClick={() => scrollClick("roadmap")}
        >
          {"Roadmap"}
        </div>
        <div
          to="/"
          className="navbar__sidebar__nav-item"
          onClick={() => scrollClick("faq")}
        >
          {"Faq"}
        </div>
        <div
          to="/"
          className="navbar__sidebar__nav-item"
          onClick={() => scrollClick("team")}
        >
          {"Team"}
        </div>
        <div
          to="/"
          className="navbar__sidebar__nav-item"
          onClick={() => scrollClick("faction")}
        >
          {"Factions"}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
