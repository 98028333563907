import { useEffect, useRef } from "react";
import { Images_Src } from "../../config/images";

const data = [
  "Play as your unique NFT In the most playable and enjoyable game in the NFT space",
  "Earn ETH through regular competitions",
  "Play against and interact with other pixel communities in the NFT space",
  "PLAY TO EARN $DARK",
  "Platinum Mint Pass to Future NFTs",
  "DAO Community - YOU DECIDE THE FUTURE...",
  "Fully- fledged web3 Galactium economy, staking, wagering and full play to earn capability to be released in the near future...",
];

const Utility = () => {
  const shipRef = useRef(null);

  const rootRef = useRef(null);

  useEffect(() => {
    document.addEventListener("scroll", calculateScrollDistance);

    return () => {
      document.removeEventListener("scroll", calculateScrollDistance);
    };
  }, []);

  const calculateScrollDistance = () => {
    const start = rootRef?.current.offsetTop;
    const height = rootRef?.current.scrollHeight;
    const end = start + height;
    const scrollTop = window.pageYOffset;
    if (scrollTop < start - 100 || scrollTop > end - 600) return;

    const distance = Math.min(
      Math.floor(((scrollTop + 100 - start) / height) * 1000),
      600
    );
    shipRef.current.style.transform = `translateY(${distance}px)`;
  };

  return (
    <div className="page-home__utility" ref={rootRef}>
      <div className="page-home__utility__title">{"utility"}</div>
      <ul className="page-home__utility__lists">
        {data.map((item, index) => (
          <li className="page-home__utility__lists__list" key={index}>
            {item}
          </li>
        ))}
      </ul>
      <img
        className="page-home__utility__ship"
        src={Images_Src.utility}
        alt="ship"
        ref={shipRef}
      />
    </div>
  );
};

export default Utility;
