export const Images_Src = {
  title: require("../assets/images/Another_with_optimised_colour.png").default,
  icons: {
    twitter: require("../assets/images/icons/twitter.png").default,
    discord: require("../assets/images/icons/discord_new.png").default,
    instagram: require("../assets/images/icons/instagram.png").default,
  },
  ships: {
    shipOne: require("../assets/images/ships/Picture1.png").default,
    shipTwo: require("../assets/images/ships/Picture2.png").default,
    shipThree: require("../assets/images/ships/116.png").default,
    shipfour: require("../assets/images/ships/226.png").default,
  },
  carousel: [
    require("../assets/images/carousel/1.png").default,
    require("../assets/images/carousel/elf1.png").default,
    require("../assets/images/carousel/4.jpg").default,
    require("../assets/images/carousel/3.png").default,
    require("../assets/images/carousel/elf5.png").default,
    require("../assets/images/carousel/2.png").default,
    require("../assets/images/carousel/1.jpg").default,
    require("../assets/images/carousel/6.png").default,
  ],
  metaverse: require("../assets/images/ships/GM.png").default,
  utility: require("../assets/images/ships/226.png").default,
  roadmap: require("../assets/images/ships/wolves.png").default,
  faq: require("../assets/images/ships/FAQ.png").default,
  will: require("../assets/images/ships/123.png").default,
  team: {
    Suptoshi: require("../assets/images/team/Suptoshi.png").default,
    Hef: require("../assets/images/team/Hef.png").default,
    Jet: require("../assets/images/team/Jet.png").default,
    Daddy: require("../assets/images/team/Daddy.png").default,
  },
};
