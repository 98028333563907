import { useRef, useState } from "react";

/*====== icons =======*/
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const QuestionAnswer = ({ question, answer }) => {
  const [accordion, setAccordion] = useState(false);
  const answerRef = useRef(null);

  return (
    <div className="question-answer">
      <div
        className="question-answer__question"
        onClick={() => {
          if (!accordion) {
            answerRef.current.style.maxHeight =
              answerRef.current.scrollHeight + "px";
          } else {
            answerRef.current.style.maxHeight = null;
          }
          setAccordion(!accordion);
        }}
      >
        {accordion ? <FaChevronDown /> : <FaChevronRight />}
        <div>{question}</div>
      </div>
      <div className="question-answer__answer" ref={answerRef}>
        {answer}
      </div>
    </div>
  );
};

export default QuestionAnswer;
