import { useRef } from "react";
import NavBar from "../../components/navbar";
import Collection from "./collection";
import Faction from "./faction";
import Faq from "./faq";
import GalacticAlliance from "./galactic-alliance";
import Metaverse from "./metaverse";
import MintingSoon from "./minting-soon";
import Roadmap from "./roadmap";
import Team from "./team";
import Utility from "./utility";

const Home = () => {
  const utilitySecRef = useRef(null);
  const roadmapSecRef = useRef(null);
  const faqSecRef = useRef(null);
  const teamSecRef = useRef(null);
  const factionSecRef = useRef(null);
  const scrollClick = (to) => {
    switch (to) {
      case "utility":
        utilitySecRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "roadmap":
        roadmapSecRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "faq":
        faqSecRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "team":
        teamSecRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "faction":
        factionSecRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        utilitySecRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="page-home">
      <NavBar scrollClick={scrollClick} />
      <MintingSoon />
      <Collection />
      <Metaverse />
      <GalacticAlliance />
      <div ref={utilitySecRef}>
        <Utility />
      </div>
      <div ref={roadmapSecRef}>
        <Roadmap />
      </div>
      <div ref={faqSecRef}>
        <Faq />
      </div>
      <div ref={teamSecRef}>
        <Team />
      </div>
      <div ref={factionSecRef}>
        <Faction />
      </div>
    </div>
  );
};

export default Home;
