import { Images_Src } from "../config/images";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Images_Src.title} alt="GALACTIUM" className="footer__logo" />
      <div className="footer__text">
        {"© 2022 Galactium All Rights Reserved"}
      </div>
    </div>
  );
};

export default Footer;
