import Footer from "../../components/footer";
import { Images_Src } from "../../config/images";

const data = [
  {
    imgSrc: Images_Src.ships.shipThree,
    title: "The Astral Elves",
    text: [
      "An ancient civilisation, older than any other faction in the Galactium Metaverse, the Elves are capable of powerful sorcery and will not hesitate to use it in destroying those who cross their path. The Elves once held dominion over the galaxy and were known for their honourable and just approach towards rule. However, their honour was their greatest weakness, and the other factions banded together to end the Reign of the Elven Empire.",
      "Since ‘The Great Shame’, the traditional authority of the Elders has been rejected in exchange for a turn towards dark forms of magic. Jaded and cynical, the younger generations wish to regain hegemony over the Galaxy through whatever means possible; they see anything less than total supremacy as a failure. Either the galaxy will be theirs, or they will be obliterated in the fight",
    ],
  },
  {
    imgSrc: Images_Src.ships.shipTwo,
    title: "The Galactic Knights",
    text: [
      "The Galactic Knights are an order of righteous, chivalrous soldiers who fight tirelessly to protect humanity. Due to the copious amounts of Selantium on their home planet, Bemoria, they possess technology which surpasses that of every other faction and the metallic hull of their spaceships are notoriously difficult to penetrate.",
      "Forged in fire, hardened by battle, the Knights have long been attacked from all sides by factions they consider to be heretics, animals or petty sorcerers. Determined and resilient, they have never succumbed to the hordes of enemies who swarm Bemoria. They are united by their shared faith in Thenis, the God of Justice, and their allegiance to the Allahastrian Dynasty.",
    ],
  },
  {
    imgSrc: Images_Src.roadmap,
    title: "The Wolven Warriors",
    text: [
      "The Wolven Warriors are a race of barbarian beasts infamous for being the most ruthless civilisation in the Galactium Metaverse. Frequently, the Wolves’ most elite warriors assemble in packs at the turn of twilight and depart from their home planet, Lykos, to embark on ‘hunts’. During hunts, wolves explore the galaxy to seek new civilisations to vanquish whilst eradicating all enemies they encounter along the way. When the Wolven Warriors return from hunts at the break of dawn, they must ensure not to return empty-handed as there are no exceptions to their merciless way of life.",
      "When facing off against the Wolves in battle, one must not be mistaken by their brutish appearance. Their sharp animal instincts and the aerodynamic construction of their ships allow them to be the swiftest fighters in the galaxy.",
    ],
  },
  {
    imgSrc: Images_Src.ships.shipfour,
    title: "The Space Pirates",
    text: [
      "A band of undead marauders, notorious for raiding enemy ships and enslaving any survivors, who are inevitably added to the ranks of the undead. Unlike the Knights, the pirates’ only guiding force is chaos itself, as they ignore alliances when it suits them and flagrantly disregard Galactic Codes of War. They do not consider themselves bound to the pointless ethical demands of the sentient races, for they serve a higher cause of annihilating all life and reducing the Universe to darkness.",
      "Due to their sheer resourcefulness, the pirates have been known to cobble pieces of alien, elven and human technology together to create formidable ships and weaponry. Indeed, other factions have marveled at the design of their ships, which appear to be half creature and half mechanical. Their cannons are so powerful that they can rip through asteroids and pierce the hull of enemies foolish enough to try and take cover.",
    ],
  },
];

const Faction = () => {
  return (
    <div className="page-home__faction">
      <div className="page-home__faction__title">{"the factions"}</div>
      <div className="page-home__faction__content">
        {data.map((data, index) => (
          <div className="page-home__faction__content__item" key={index}>
            <div className="page-home__faction__content__item__img">
              <img src={data.imgSrc} alt="ship" />
            </div>
            <div className="page-home__faction__content__item__description">
              <div className="page-home__faction__content__item__description__title">
                {data.title}
              </div>
              <div className="page-home__faction__content__item__description__content">
                {data.text.map((text, index) => (
                  <div
                    className="page-home__faction__content__item__description__content__text"
                    key={index}
                  >
                    {text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Faction;
