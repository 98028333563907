import ScrollAnimation from "react-animate-on-scroll";

import { Images_Src } from "../../config/images";
import QuestionAnswer from "../../components/question-answer";
const data = [
  {
    question: "When will the Mint day be? ",
    answer:
      "The exact mint date of private and public release will be released on twitter, discord and the website later on. The private presale will occur 24 hours before public release.",
  },
  {
    question: "Mint Price?",
    answer:
      "This will be decided in accordance with the community’s feedback closer to the mint date.",
  },
  {
    question: "How do I get on the whitelist for presale?",
    answer:
      "There will be numerous giveaways, community events and in-game competitions on twitter and discord. Continued positive contributions to the community on discord will always be rewarded.",
  },
  {
    question: "How do I mint my Galactium NFT?",
    answer:
      "Connect your metamask wallet to our site by clicking on the ‘CONNECT METAMASK’ button. Once connected, select how many Space Game NFTs you would like to mint, and click the ‘MINT’ button. Once minted, check your Metamask wallet for your transaction details, and then click on ‘VIEW ON OPENSEA’ to see its unique appearance!",
  },

  {
    question: "Which Blockhain network are you using?",
    answer:
      "Our genesis NFTs will mint on the Ethereum network. However, as we build a complex in-game trading economy in the long term, we will expand to a Layer 2 solution such as Polygon , Arbitrum or Immutable X to eliminate all gas fees. Ultimately, this will make Galactium a L1+L2 hybrid.",
  },
  {
    question: "Will the NFTS have rarities?",
    answer:
      "Yes, each ship and pilot is compiled from a number of traits, which can be tracked via rarity.tools to assess their rarity. However, rarity is not integral to the game as we aim to make our in-game experience a level playing field.",
  },
  {
    question: "What will be the difference between different factions?",
    answer:
      "Each faction has its own unique type of ship and pilot character design, which will be reflected in game. The faction which your NFT belongs to will change the in-game passive + active fighting abilities you have and your overall gameplay experience. Think of super smash bros but better because your character is unique and different from everyone else.",
  },
];

const Faq = () => {
  return (
    <div className="page-home__faq">
      <div className="page-home__faq__title">{"faq"}</div>
      <div className="page-home__faq__content">
        <div className="page-home__faq__content__lists">
          {data.map((data, index) => (
            <QuestionAnswer
              question={data.question}
              answer={data.answer}
              key={index}
            />
          ))}
        </div>
        <div className="page-home__faq__content__img">
          <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
            <img src={Images_Src.faq} alt="ship" />
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default Faq;
