import { Images_Src } from "../../config/images";
import ScrollAnimation from "react-animate-on-scroll";
const Metaverse = () => {
  return (
    <div className="page-home__metaverse">
      <div className="page-home__metaverse__content">
        <div className="page-home__metaverse__content__title">
          {"The Galactium Metaverse"}
        </div>
        <div className="page-home__metaverse__content__text">
          {
            "In Galactium, dark matter ($dark) is the resource which powers the galaxy and has become alarmingly scarce. A centuries-long conflict with no end in sight was ignited as four factions warred over their share of $dark."
          }
        </div>
        <div className="page-home__metaverse__content__text" style={{marginTop: '2%'}}>
          {
            "Tensions between the Galactic Knights, Space Pirates, Wolven Warriors and Astral/Dark Elves can only be resolved through combat, as the factions’ most elite fighter pilots face off in deep space and use their unique fighting abilities to secure $dark and expand their factions’ dominion over the galaxy..."
          }
        </div>
      </div>
      <div className="page-home__metaverse__image">
        <ScrollAnimation animateIn="slideInDown" animateOnce={true}>
          <img src={Images_Src.metaverse} alt="ship" />
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Metaverse;
