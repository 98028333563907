const GalacticAlliance = () => {
    return (<div className="page-home__galactic-alliance">
        <div className="page-home__galactic-alliance__title">
            {"galactic alliances"}
        </div>
        <div className="page-home__galactic-alliance__bring-text" style={{marginTop: '30px'}}>
            {"Bringing NFT communities to the Galactium metaverse..."}
        </div>
        <div className="page-home__galactic-alliance__announce-text">
            {"Announcements coming soon...."}
        </div>
    </div>)
}

export default GalacticAlliance