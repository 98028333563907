import ScrollAnimation from "react-animate-on-scroll";
import { Images_Src } from "../../config/images";

const Team = () => {
  return (
    <div className="page-home__team">
      <div className="page-home__team__title">{"SPACE HQ"}</div>
      <div className="page-home__team__content">
        <ScrollAnimation animateIn="slideInDown" animateOnce={true}>
          <a
            href="https://twitter.com/GalactiumNFT"
            target="_blank"
            rel="noreferrer"
          >
            <div className="page-home__team__content__card">
              <img src={Images_Src.team.Hef} alt="ship" />
              <div>{"The Heffa"}</div>
              <div>{"Founder of Galactium"}</div>
            </div>
          </a>
        </ScrollAnimation>
        <ScrollAnimation animateIn="slideInDown" animateOnce={true}>
          <div className="page-home__team__content__card">
            <img src={Images_Src.team.Suptoshi} alt="ship" />
            <div>{"Suptoshi"}</div>
            <div>{"Developer"}</div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
          <a
            href="https://twitter.com/NubbiesNFT"
            target="_blank"
            rel="noreferrer"
          >
            <div className="page-home__team__content__card">
              <img src={Images_Src.team.Jet} alt="ship" />
              <div>{"Jetlauncher"}</div>
              <div>{"Advisor"}</div>
            </div>
          </a>
        </ScrollAnimation>
      </div>
      <div style={{ height: "500px" }} />
    </div>
  );
};

export default Team;
